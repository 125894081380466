<template>
  <div class="fluid-container footer">
    1. Duisburg Hamborner Reiterverein 1926 e.V. &bull; Geschlossener Bereich für Mitglieder und Freunde &bull; Version {{$version}}
  </div>
</template>
<style>
  .fluid-container.footer {
    background: lightgray;
    position: fixed;
    width:100%;
    height: 2em;
    padding: 4px;
    font-size: 12px;
    color: white;
    bottom: 0;
  }
    .fluid-container.footer > *:last-child {
      margin-bottom: 0px;
      color: #fff;
    }
</style>
