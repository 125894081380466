<template>
    <div class="schedule-vue-sample">
        <div class="col-md-12 control-section">
            <div class="content-wrapper">
                <ejs-schedule locale='de' id="Schedule" ref="ScheduleObj" height="100%" :currentView='scheduleView' :selectedDate='selectedDate' :eventSettings='eventSettings' :eventRendered="oneventRendered">
                    <e-views>
                        <e-view option="Day"></e-view>
                        <e-view option="Week"></e-view>
                        <e-view option="WorkWeek"></e-view>
                        <e-view option="Month"></e-view>
                    </e-views>
                </ejs-schedule>
            </div>
        </div>
    </div>
</template>
<script>
    import { zooEventsData } from './datasource';
    import { extend } from '@syncfusion/ej2-base';
    import { ScheduleComponent, ViewDirective, ViewsDirective, Day, Week, WorkWeek, Month, Resize, DragAndDrop } from "@syncfusion/ej2-vue-schedule";
    import { L10n, loadCldr } from '@syncfusion/ej2-base';

    L10n.load(require("../../src/Common//locale-text.json"));

    loadCldr(
        require('../../src/Common/cldr-data/main/de/ca-gregorian.json'),
        require('../../src/Common/cldr-data/main/de/numbers.json'),
        require('../../src/Common/cldr-data/main/de/timeZoneNames.json'),
        require('../../src/Common/cldr-data/supplemental/numberingSystems.json')
    );

    export default {
        components: {
            'ejs-schedule': ScheduleComponent,
            'e-view': ViewDirective,
            'e-views': ViewsDirective,
        },
        data: function () {
            return {
                eventSettings: { dataSource: extend([], zooEventsData, null, true) },
                selectedDate: new Date(2021, 1, 15),
                datas: ['Day', 'Week', 'WorkWeek', 'Month'],
                scheduleView: 'Week'
            }
        },
        provide: {
            schedule: [Day, Week, WorkWeek, Month, Resize, DragAndDrop]
        },
        methods: {
            changevalue: function (args) {
                this.$refs.ScheduleObj.ej2Instances.currentView = args.value;
            },
            oneventRendered: function (args) {
                let categoryColor = args.data.CategoryColor;
                if (!args.element || !categoryColor) {
                    return;
                }
                args.element.style.backgroundColor = categoryColor;
            }
        }
    }

</script>