import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { registerLicense } from '@syncfusion/ej2-base';
import { enableRipple } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF1cXmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjW39bcHNVQmVdWUZ/Xg==');
enableRipple(true);
//DebugUrl https://localhost:7260/api/ ProdUrl https://services.rv-duisburg.de/api/
const app = createApp(App)
app.config.globalProperties.$version = "1.0.0.0"
app.config.globalProperties.$serviceBaseUrl = "https://services.rv-duisburg.de/api/"
app.use(store).use(router).mount("#app");