<template>
    <div id='container' v-bind:style="{opacity: opacity}">
        <div class="e-card-header center">
            <div class="e-card-header-caption center">
                <img src="https://rv-duisburg.de/wp-content/uploads/2023/06/60D2C45B-06F1-45E5-8BFD-2E02F5C4D6A4.png" height="180px" />
                <p class="center">Anmeldung</p>
            </div>
        </div>
        <div class="e-card-content center">
            <div>
                <ejs-textbox floatLabelType="Auto" placeholder="Benutzername" v-model="username"></ejs-textbox>
                <ejs-textbox ref="pwd" :htmlAttributes="htmlAttributes" floatLabelType="Auto" placeholder="Password" v-model="passwd"></ejs-textbox>
                <span v-if="showpw" class="e-icons e-eye eyeclass" @click="showpw = !showpw"></span>
                <span v-if="!showpw" class="e-icons e-eye-slash eyeclass" @click="showpw = !showpw"></span>
            </div>
        </div>
        <div class="e-card-content center">
            <div class="buttonClassL">
                <ejs-button cssClass='e-primary' v-on:click="register">Registrieren</ejs-button>
            </div>
            <div class="buttonClassR e-primary">
                <ejs-button cssClass='e-primary' :disabled="!noLogin" v-on:click="login">Anmelden</ejs-button>
            </div>
        </div>
        <div class="msgclass">
            <ejs-message cssClass="e-content-center" v-if="error" id="msg_error_outlined" severity="Error">{{ errorMsg }}</ejs-message>
        </div>
    </div>
</template>

<script>
    // import headers from "../lib/headers.js"
    import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
    import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
    import { MessageComponent } from '@syncfusion/ej2-vue-notifications';
    import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';

    export default {
        components: {
            'ejs-textbox': TextBoxComponent,
            'ejs-button': ButtonComponent,
            'ejs-message': MessageComponent
        },
        data() {
            return {
                htmlAttributes: { type: "password" },
                username: '',
                passwd: '',
                noLogin: false,
                showpw: false,
                pwtype: 'password',
                error: false,
                errorMsg: '',
                opacity: 1
            }
        },
        mounted: function () {
            createSpinner({
                target: document.getElementById("container"),
            });
        },
        watch: {
            username: function (value) {
                this.noLogin = false
                if (value && this.passwd) {
                    this.noLogin = true
                }
            },
            passwd: function (value) {
                this.noLogin = false
                if (value && this.username) {
                    this.noLogin = true
                }
            },
            showpw: function (value) {
                this.$refs.pwd.$el.type = value ? 'text' : 'password'
            }
        },
        methods: {
            async login() {
                this.error = false;
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({ userName: this.username, password: this.passwd })
                }

                this.opacity = .5;
                showSpinner(document.getElementById('container'));

                await fetch(this.$serviceBaseUrl + 'Account/Login', requestOptions)
                    .then(async resp => {
                        const data = await resp.json()
                        if (!resp.ok) {
                            const error = (data && data.message) || resp.status
                            return Promise.reject(error)
                        }
                        this.token = data.token
                        localStorage.setItem('token', this.token)
                        const headers = {
                            'Authorization': 'Bearer ' + this.token
                        };
                        await fetch(this.$serviceBaseUrl + 'Account/UserInformation?stoken=' + this.token, { headers })
                            .then(async resp => {
                                const data = await resp.json()
                                if (resp.ok) {
                                    localStorage.setItem('firstname', data.firstname)
                                    localStorage.setItem('lastname', data.lastname)
                                    localStorage.setItem('email', data.email)
                                    localStorage.setItem('confirmed', data.confirmed)
                                    localStorage.setItem('securityAnswer', data.securityAnswer)
                                }
                            })
                        hideSpinner(document.getElementById('container'));
                        this.opacity = 1;
                        this.$router.push({ name: 'home' })
                    })
                    .catch((err) => {
                        hideSpinner(document.getElementById('container'));
                        this.opacity = 1;
                        switch (err) {
                            case 401:
                                {
                                    this.showError('Login fehlerhaft. Benutzer ist unbekannt oder das Password ist nicht korrekt.')
                                    break;
                                }
                            default:
                                {
                                    this.showError('Unterstützungsdienste sind nicht erreichbar. ' + err)
                                }
                        }
                    })
            },
            showError(err) {
                this.error = true
                this.errorMsg = err
            },
            register() {
                this.$router.push({ name: 'register' })
            }
        }
    }
</script>
<style>
    .buttonClassR {
        float: right;
    }

    .buttonClassL {
        float: left;
    }

    .msgclass {
        margin: 0 auto;
        max-width: 400px;
        padding-top: 10px;
    }

    .eyeclass {
        float: right;
        position: relative;
        top: -22px;
    }

    @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
    @import "../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
    @import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";
    @import "../../node_modules/@syncfusion/ej2-vue-notifications/styles/message/material.css";
    @import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
</style>
